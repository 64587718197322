import {
  createAxiosLogistica,
  createAxiosHeadersLogistica,
} from "./axiosHttpMarconi";
import { franquiciaService } from "./franquicia.service";

export const ordenesEntradaService = {
  getOrdenesEntrada,
  cancelOrdenEntradaById,
  postOrdenEntrada,
  updateOrdenEntrada,
  getOrdenEntradaById,
  ubicarItem,
  copiarOrdenEntradaById,
  downloadById,
};

function getOrdenesEntrada(pagination, filters) {
  this.usuario = JSON.parse(localStorage.getItem("user"));
  if (this.usuario.agrupacion)
    filters.franquicia = franquiciaService.getLocalFranquicia()._id;
  const axios = createAxiosLogistica();
  let urlPath = `logistica/orden-entrada`;
  const params = new URLSearchParams({ ...pagination, ...filters });

  urlPath += `?${params.toString()}`;
  return axios.get(urlPath);
}

function cancelOrdenEntradaById(ordenEntradaId) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/orden-entrada/${ordenEntradaId}`;
  return axios.delete(urlPath);
}

function copiarOrdenEntradaById(ordenEntradaId) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/orden-entrada/${ordenEntradaId}/copiar`;
  return axios.post(urlPath);
}

function downloadById(ordenEntradaId) {
  const axios = createAxiosHeadersLogistica();
  let urlPath = `logistica/orden-entrada/${ordenEntradaId}/descarga`;
  return axios.get(urlPath, { responseType: "blob" }).then((response) => {
    const contentDisposition = response.headers["content-disposition"];
    const fileName = contentDisposition;
    return Promise.resolve({ blob: response.data, fileName: fileName });
  });
}

function postOrdenEntrada(request) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/orden-entrada`;
  return axios.post(urlPath, request);
}

function updateOrdenEntrada(id, body) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/orden-entrada/${id}`;
  return axios.put(urlPath, body);
}

function getOrdenEntradaById(id) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/orden-entrada/${id}`;
  return axios.get(urlPath);
}

function ubicarItem(id, body, query) {
  const axios = createAxiosLogistica();
  const franquicia = franquiciaService.getLocalFranquicia();
  query.franquicia = franquicia._id;
  let urlPath = `logistica/orden-entrada/${id}/ubicar`;
  const params = new URLSearchParams(query);

  urlPath += `?${params.toString()}`;
  return axios.post(urlPath, body);
}
