import { stockService } from "@/_services";
export const stock = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    listMove: {
      data: [],
      status: {},
    },
    move: {
      data: {},
      status: {},
    },
    ajuste: {
      data: {},
      status: {},
    },
    excel: {
      data: [],
      status: {},
    },
  },
  mutations: {
    getStockRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getStockSuccess(state, response) {
      state.list.data = response;
      state.list.status = {
        loaded: true,
      };
    },
    getStockError(state, error) {
      state.list.data = null;
      state.list.status = {
        error,
      };
    },
    getStockMoveRequest(state) {
      state.listMove.status = {
        loading: true,
      };
    },
    getStockMoveSuccess(state, response) {
      state.listMove.data = response;
      state.listMove.status = {
        loaded: true,
      };
    },
    getStockMoveError(state, error) {
      state.listMove.data = null;
      state.listMove.status = {
        error,
      };
    },
    moveStockRequest(state) {
      state.move.status = {
        moving: true,
      };
    },
    moveStockSuccess(state, response) {
      state.move.data = response;
      state.move.status = {
        moved: true,
      };
    },
    moveStockError(state, error) {
      state.move.data = null;
      state.move.status = {
        error,
      };
    },
    adjustStockRequest(state) {
      state.ajuste.status = {
        adjusting: true,
      };
    },
    adjustStockSuccess(state, response) {
      state.ajuste.data = response;
      state.ajuste.status = {
        adjusted: true,
      };
    },
    adjustStockError(state, error) {
      state.ajuste.data = null;
      state.ajuste.status = {
        error,
      };
    },
    getExcelRequest(state) {
      state.excel.status = {
        downloading: true,
      };
    },
    getExcelError(state, error) {
      state.excel.data = null;
      state.excel.status = {
        error,
      };
    },
    getExcelSuccess(state, response) {
      state.excel.data = response;
      state.excel.status = {
        downloaded: true,
      };
    },
  },
  actions: {
    getStock({ commit }, request) {
      commit("getStockRequest");
      stockService
        .getStock(request.pagination, request.query)
        .then((response) => {
          commit("getStockSuccess", response);
        })
        .catch((error) => {
          commit("getStockError", error);
        });
    },
    getStockMove({ commit }, request) {
      commit("getStockMoveRequest");
      stockService
        .getStock(request.pagination, request.query)
        .then((response) => {
          commit("getStockMoveSuccess", response);
        })
        .catch((error) => {
          commit("getStockMoveError", error);
        });
    },
    moveStock({ commit }, body) {
      commit("moveStockRequest");
      stockService
        .moveStock(body)
        .then((response) => {
          commit("moveStockSuccess", response);
        })
        .catch((error) => {
          commit("moveStockError", error);
        });
    },
    ajustarStock({ commit }, body) {
      commit("adjustStockRequest");
      stockService
        .ajustarStock(body)
        .then((response) => {
          commit("adjustStockSuccess", response);
        })
        .catch((error) => {
          commit("adjustStockError", error);
        });
    },
    getExcel({ commit }, filters) {
      commit("getExcelRequest");
      stockService
        .getExcel(filters)
        .then((res) => {
          commit("getExcelSuccess", res);
        })
        .catch((error) => {
          commit("getExcelError", error);
        });
    },
  },
};
