import { ticketBaiService } from "@/_services";

export const ticketBai = {
  namespaced: true,
  state: {
    entity: {
      data: [],
      status: {},
    },
    register: {
      data: [],
      status: {},
    },
  },
  mutations: {
    getConfigRequest(state) {
      state.entity.status = {
        loading: true,
      };
    },
    getConfigError(state, error) {
      state.entity.data = [];
      state.entity.status = {
        loading: false,
        error,
      };
    },
    getConfigSuccess(state, res) {
      state.entity.data = res;
      state.entity.status = {
        loaded: true,
      };
    },
    registerRequest(state) {
      state.register.status = {
        loading: true,
      };
    },
    registerError(state, error) {
      state.register.data = [];
      state.register.status = {
        loading: false,
        error,
      };
    },
    registerSuccess(state, res) {
      state.register.data = res;
      state.register.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getConfig({ commit }) {
      commit("getConfigRequest");
      ticketBaiService
        .getConfig()
        .then((res) => {
          commit("getConfigSuccess", res);
        })
        .catch((error) => {
          commit("getConfigError", error);
        });
    },
    registerConfig({ commit }, body) {
      commit("registerRequest");
      ticketBaiService
        .registerConfig(body)
        .then((res) => {
          commit("registerSuccess", res);
        })
        .catch((error) => {
          commit("registerError", error);
        });
    },
  },
};
