import { almacenesService } from "@/_services";
export const almacen = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    entity: {
      data: [],
      status: {},
    },
    delete: {
      data: [],
      status: {},
    },
    update: {
      data: [],
      status: {},
    },
    post: {
      data: [],
      status: {},
    },
  },
  mutations: {
    getAlmacenesRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getAlmacenesSuccess(state, response) {
      state.list.data = response;
      state.list.status = {
        loaded: true,
      };
    },
    getAlmacenesError(state, error) {
      state.list.data = null;
      state.list.status = {
        error,
      };
    },
    deleteAlmacenRequest(state) {
      state.delete.status = {
        deleting: true,
      };
    },
    deleteAlmacenError(state, error) {
      state.delete.data = null;
      state.delete.status = {
        error,
      };
    },
    deleteAlmacenSuccess(state, response) {
      state.delete.data = response;
      state.delete.status = {
        deleted: true,
      };
    },
    postAlmacenRequest(state) {
      state.post.status = {
        creating: true,
      };
    },
    postAlmacenError(state, error) {
      state.post.data = null;
      state.post.status = {
        error,
      };
    },
    postAlmacenSuccess(state, response) {
      state.post.data = response;
      state.post.status = {
        created: true,
      };
    },
    updateAlmacenRequest(state) {
      state.update.status = {
        updating: true,
      };
    },
    updateAlmacenError(state, error) {
      state.update.data = null;
      state.update.status = {
        error,
      };
    },
    updateAlmacenSuccess(state, response) {
      state.update.data = response;
      state.update.status = {
        updated: true,
      };
    },
    getAlmacenByIdRequest(state) {
      state.entity.status = {
        loading: true,
      };
    },
    getAlmacenByIdError(state, error) {
      state.entity.data = null;
      state.entity.status = {
        error,
      };
    },
    getAlmacenByIdSuccess(state, response) {
      state.entity.data = response;
      state.entity.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getAlmacenes({ commit }, request) {
      commit("getAlmacenesRequest");
      almacenesService
        .getAlmacenes(request.pagination, request.query)
        .then((response) => {
          commit("getAlmacenesSuccess", response);
        })
        .catch((error) => {
          commit("getAlmacenesError", error);
        });
    },
    deleteAlmacen({ commit }, id) {
      commit("deleteAlmacenRequest");
      almacenesService
        .deleteAlmacen(id)
        .then((res) => {
          commit("deleteAlmacenSuccess", res);
        })
        .catch((error) => {
          commit("deleteAlmacenError", error);
        });
    },
    postAlmacen({ commit }, request) {
      commit("postAlmacenRequest");
      almacenesService
        .postAlmacen(request)
        .then((res) => {
          commit("postAlmacenSuccess", res);
        })
        .catch((error) => {
          commit("postAlmacenError", error);
        });
    },
    updateAlmacen({ commit }, request) {
      commit("updateAlmacenRequest");
      almacenesService
        .updateAlmacen(request.id, request.body)
        .then((res) => {
          commit("updateAlmacenSuccess", res);
        })
        .catch((error) => {
          commit("updateAlmacenError", error);
        });
    },
    getAlmacenById({ commit }, id) {
      commit("getAlmacenByIdRequest");
      almacenesService
        .getAlmacenById(id)
        .then((res) => {
          commit("getAlmacenByIdSuccess", res);
        })
        .catch((error) => {
          commit("getAlmacenByIdError", error);
        });
    },
  },
};
