import {
  createAxiosLogistica,
  createAxiosHeadersLogistica,
} from "./axiosHttpMarconi";
import { franquiciaService } from "./franquicia.service";

export const ordenesSalidaService = {
  getOrdenesSalida,
  cancelOrdenSalidaById,
  postOrdenSalida,
  updateOrdenSalida,
  getOrdenSalidaById,
  ubicarItem,
  copiarOrdenSalidaById,
  expedirOrdenSalida,
  downloadById,
  getOrdenSalidaStockById,
};

function getOrdenesSalida(pagination, filters) {
  this.usuario = JSON.parse(localStorage.getItem("user"));
  if (this.usuario.agrupacion)
    filters.franquicia = franquiciaService.getLocalFranquicia()._id;
  const axios = createAxiosLogistica();
  let urlPath = `logistica/orden-salida`;
  const params = new URLSearchParams({ ...pagination, ...filters });

  urlPath += `?${params.toString()}`;
  return axios.get(urlPath);
}

function cancelOrdenSalidaById(ordenSalidaId) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/orden-salida/${ordenSalidaId}`;
  return axios.delete(urlPath);
}

function postOrdenSalida(request) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/orden-salida`;
  return axios.post(urlPath, request);
}

function updateOrdenSalida(id, body) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/orden-salida/${id}`;
  return axios.put(urlPath, body);
}

function expedirOrdenSalida(id, body) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/orden-salida/${id}/expedir`;
  return axios.post(urlPath, body);
}

function getOrdenSalidaById(id) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/orden-salida/${id}`;
  return axios.get(urlPath);
}

function ubicarItem(id, body, query) {
  const axios = createAxiosLogistica();
  const franquicia = franquiciaService.getLocalFranquicia();
  query.franquicia = franquicia._id;
  let urlPath = `logistica/orden-salida/${id}/ubicar`;
  const params = new URLSearchParams(query);

  urlPath += `?${params.toString()}`;
  return axios.post(urlPath, body);
}

function copiarOrdenSalidaById(ordenSalidaId) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/orden-salida/${ordenSalidaId}/copiar`;
  return axios.post(urlPath);
}

function downloadById(ordenSalidaId) {
  const axios = createAxiosHeadersLogistica();
  let urlPath = `logistica/orden-salida/${ordenSalidaId}/descarga`;
  return axios.get(urlPath, { responseType: "blob" }).then((response) => {
    const contentDisposition = response.headers["content-disposition"];
    const fileName = contentDisposition;
    return Promise.resolve({ blob: response.data, fileName: fileName });
  });
}

function getOrdenSalidaStockById(id) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/orden-salida/${id}/stock`;
  return axios.get(urlPath);
}
