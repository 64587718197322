import { createAxiosLogistica } from "./axiosHttpMarconi";
import { franquiciaService } from "./franquicia.service";

export const logisticaValoracionService = { getValoraciones };

function getValoraciones(pagination, filters) {
  this.usuario = JSON.parse(localStorage.getItem("user"));
  if (this.usuario.agrupacion)
    filters.franquicia = franquiciaService.getLocalFranquicia()._id;
  const axios = createAxiosLogistica();
  let urlPath = `logistica/valoracion`;
  const params = new URLSearchParams({ ...pagination, ...filters });

  urlPath += `?${params.toString()}`;
  return axios.get(urlPath);
}
