import { createAxiosLogistica } from "./axiosHttpMarconi";

export const medidasService = {
  getMedidas,
};

function getMedidas(query) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/medida`;
  const params = new URLSearchParams({ ...query });

  urlPath += `?${params.toString()}`;
  return axios.get(urlPath);
}
