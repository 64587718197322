import { ubicacionesService } from "@/_services";
export const ubicacion = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    entity: {
      data: [],
      status: {},
    },
    delete: {
      data: [],
      status: {},
    },
    update: {
      data: [],
      status: {},
    },
    post: {
      data: [],
      status: {},
    },
    etiquetas: {
      data: [],
      status: {},
    },
    excel: {
      data: [],
      status: {},
    },
  },
  mutations: {
    getUbicacionesRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getUbicacionesSuccess(state, response) {
      state.list.data = response;
      state.list.status = {
        loaded: true,
      };
    },
    getUbicacionesError(state, error) {
      state.list.data = null;
      state.list.status = {
        error,
      };
    },
    deleteUbicacionRequest(state) {
      state.delete.status = {
        deleting: true,
      };
    },
    deleteUbicacionError(state, error) {
      state.delete.data = null;
      state.delete.status = {
        error,
      };
    },
    deleteUbicacionSuccess(state, response) {
      state.delete.data = response;
      state.delete.status = {
        deleted: true,
      };
    },
    postUbicacionRequest(state) {
      state.post.status = {
        creating: true,
      };
    },
    postUbicacionError(state, error) {
      state.post.data = null;
      state.post.status = {
        error,
      };
    },
    postUbicacionSuccess(state, response) {
      state.post.data = response;
      state.post.status = {
        created: true,
      };
    },
    updateUbicacionRequest(state) {
      state.update.status = {
        updating: true,
      };
    },
    updateUbicacionError(state, error) {
      state.update.data = null;
      state.update.status = {
        error,
      };
    },
    updateUbicacionSuccess(state, response) {
      state.update.data = response;
      state.update.status = {
        updated: true,
      };
    },
    getUbicacionByIdRequest(state) {
      state.entity.status = {
        loading: true,
      };
    },
    getUbicacionByIdError(state, error) {
      state.entity.data = null;
      state.entity.status = {
        error,
      };
    },
    getUbicacionByIdSuccess(state, response) {
      state.entity.data = response;
      state.entity.status = {
        loaded: true,
      };
    },
    getEtiquetasRequest(state) {
      state.etiquetas.status = {
        downloading: true,
      };
    },
    getEtiquetasError(state, error) {
      state.etiquetas.data = null;
      state.etiquetas.status = {
        error,
      };
    },
    getEtiquetasSuccess(state, response) {
      state.etiquetas.data = response;
      state.etiquetas.status = {
        downloaded: true,
      };
    },
    getExcelRequest(state) {
      state.excel.status = {
        downloading: true,
      };
    },
    getExcelError(state, error) {
      state.excel.data = null;
      state.excel.status = {
        error,
      };
    },
    getExcelSuccess(state, response) {
      state.excel.data = response;
      state.excel.status = {
        downloaded: true,
      };
    },
  },
  actions: {
    getUbicaciones({ commit }, request) {
      commit("getUbicacionesRequest");
      ubicacionesService
        .getUbicaciones(request.pagination, request.query)
        .then((response) => {
          commit("getUbicacionesSuccess", response);
        })
        .catch((error) => {
          commit("getUbicacionesError", error);
        });
    },
    deleteUbicacion({ commit }, id) {
      commit("deleteUbicacionRequest");
      ubicacionesService
        .deleteUbicacion(id)
        .then((res) => {
          commit("deleteUbicacionSuccess", res);
        })
        .catch((error) => {
          commit("deleteUbicacionError", error);
        });
    },
    postUbicacion({ commit }, request) {
      commit("postUbicacionRequest");
      ubicacionesService
        .postUbicacion(request)
        .then((res) => {
          commit("postUbicacionSuccess", res);
        })
        .catch((error) => {
          commit("postUbicacionError", error);
        });
    },
    updateUbicacion({ commit }, request) {
      commit("updateUbicacionRequest");
      ubicacionesService
        .updateUbicacion(request.id, request.body)
        .then((res) => {
          commit("updateUbicacionSuccess", res);
        })
        .catch((error) => {
          commit("updateUbicacionError", error);
        });
    },
    getUbicacionById({ commit }, id) {
      commit("getUbicacionByIdRequest");
      ubicacionesService
        .getUbicacionById(id)
        .then((res) => {
          commit("getUbicacionByIdSuccess", res);
        })
        .catch((error) => {
          commit("getUbicacionByIdError", error);
        });
    },
    getEtiquetas({ commit }, filters) {
      commit("getEtiquetasRequest");
      ubicacionesService
        .getEtiquetas(filters)
        .then((res) => {
          commit("getEtiquetasSuccess", res);
        })
        .catch((error) => {
          commit("getEtiquetasError", error);
        });
    },
    getExcel({ commit }, filters) {
      commit("getExcelRequest");
      ubicacionesService
        .getExcel(filters)
        .then((res) => {
          commit("getExcelSuccess", res);
        })
        .catch((error) => {
          commit("getExcelError", error);
        });
    },
  },
};
