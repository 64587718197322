import { stockHistoryService } from "@/_services";
export const stockHistory = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    excel: {
      data: [],
      status: {},
    },
  },
  mutations: {
    getStockHistoryRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getStockHistorySuccess(state, response) {
      state.list.data = response;
      state.list.status = {
        loaded: true,
      };
    },
    getStockHistoryError(state, error) {
      state.list.data = null;
      state.list.status = {
        error,
      };
    },
    getExcelRequest(state) {
      state.excel.status = {
        downloading: true,
      };
    },
    getExcelError(state, error) {
      state.excel.data = null;
      state.excel.status = {
        error,
      };
    },
    getExcelSuccess(state, response) {
      state.excel.data = response;
      state.excel.status = {
        downloaded: true,
      };
    },
  },
  actions: {
    getStockHistory({ commit }, request) {
      commit("getStockHistoryRequest");
      stockHistoryService
        .getStockHistory(request.pagination, request.query)
        .then((response) => {
          commit("getStockHistorySuccess", response);
        })
        .catch((error) => {
          commit("getStockHistoryError", error);
        });
    },
    getExcel({ commit }, filters) {
      commit("getExcelRequest");
      stockHistoryService
        .getExcel(filters)
        .then((res) => {
          commit("getExcelSuccess", res);
        })
        .catch((error) => {
          commit("getExcelError", error);
        });
    },
  },
};
