import {
  createAxiosLogistica,
  createAxiosHeadersLogistica,
} from "./axiosHttpMarconi";
import { franquiciaService } from "./franquicia.service";

export const ubicacionesService = {
  getUbicaciones,
  deleteUbicacion,
  postUbicacion,
  updateUbicacion,
  getUbicacionById,
  getEtiquetas,
  getExcel,
};

function getUbicaciones(pagination, filters) {
  this.usuario = JSON.parse(localStorage.getItem("user"));
  if (this.usuario.agrupacion)
    filters.franquicia = franquiciaService.getLocalFranquicia()._id;
  const axios = createAxiosLogistica();
  let urlPath = `logistica/ubicacion`;
  const params = new URLSearchParams({ ...pagination, ...filters });

  urlPath += `?${params.toString()}`;
  return axios.get(urlPath);
}

function deleteUbicacion(ubicacionId) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/ubicacion/${ubicacionId}`;
  return axios.delete(urlPath);
}

function postUbicacion(request) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/ubicacion`;
  return axios.post(urlPath, request);
}

function updateUbicacion(id, body) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/ubicacion/${id}`;
  return axios.put(urlPath, body);
}

function getUbicacionById(id) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/ubicacion/${id}`;
  return axios.get(urlPath);
}

function getEtiquetas(filters) {
  this.usuario = JSON.parse(localStorage.getItem("user"));
  if (this.usuario.agrupacion)
    filters.franquicia = franquiciaService.getLocalFranquicia()._id;
  const axios = createAxiosHeadersLogistica();
  let urlPath = `logistica/ubicacion/etiqueta`;
  const params = new URLSearchParams(filters);

  urlPath += `?${params.toString()}`;
  return axios.get(urlPath, { responseType: "blob" }).then((response) => {
    const contentDisposition = response.headers["content-disposition"];
    const fileName = contentDisposition;
    return Promise.resolve({ blob: response.data, fileName: fileName });
  });
}

function getExcel(filters) {
  this.usuario = JSON.parse(localStorage.getItem("user"));
  if (this.usuario.agrupacion)
    filters.franquicia = franquiciaService.getLocalFranquicia()._id;

  const axios = createAxiosHeadersLogistica();
  let urlPath = `logistica/ubicacion/excel`;
  const params = new URLSearchParams(filters);

  urlPath += `?${params.toString()}`;
  return axios.get(urlPath, { responseType: "blob" }).then((response) => {
    const contentDisposition = response.headers["content-disposition"];
    const fileName = contentDisposition;
    return Promise.resolve({ blob: response.data, fileName: fileName });
  });
}
