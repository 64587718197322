import {
  createAxiosLogistica,
  createAxiosHeadersLogistica,
} from "./axiosHttpMarconi";
import { franquiciaService } from "./franquicia.service";

export const articulosService = {
  getArticulos,
  deleteArticulo,
  postArticulo,
  updateArticulo,
  getArticuloById,
  getEtiquetas,
  getExcel,
};

function getArticulos(pagination, filters) {
  this.usuario = JSON.parse(localStorage.getItem("user"));
  if (this.usuario.agrupacion)
    filters.franquicia = franquiciaService.getLocalFranquicia()._id;
  const axios = createAxiosLogistica();
  let urlPath = `logistica/articulo`;
  const params = new URLSearchParams({ ...pagination, ...filters });

  urlPath += `?${params.toString()}`;
  return axios.get(urlPath);
}

function deleteArticulo(articuloId) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/articulo/${articuloId}`;
  return axios.delete(urlPath);
}

function postArticulo(request) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/articulo`;
  return axios.post(urlPath, request);
}

function updateArticulo(id, body) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/articulo/${id}`;
  return axios.put(urlPath, body);
}

function getArticuloById(id) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/articulo/${id}`;
  return axios.get(urlPath);
}

function getEtiquetas(filters) {
  this.usuario = JSON.parse(localStorage.getItem("user"));
  if (this.usuario.agrupacion)
    filters.franquicia = franquiciaService.getLocalFranquicia()._id;

  const axios = createAxiosHeadersLogistica();
  let urlPath = `logistica/articulo/etiqueta`;
  const params = new URLSearchParams(filters);

  urlPath += `?${params.toString()}`;
  return axios.get(urlPath, { responseType: "blob" }).then((response) => {
    const contentDisposition = response.headers["content-disposition"];
    const fileName = contentDisposition;
    return Promise.resolve({ blob: response.data, fileName: fileName });
  });
}

function getExcel(filters) {
  this.usuario = JSON.parse(localStorage.getItem("user"));
  if (this.usuario.agrupacion)
    filters.franquicia = franquiciaService.getLocalFranquicia()._id;

  const axios = createAxiosHeadersLogistica();
  let urlPath = `logistica/articulo/excel`;
  const params = new URLSearchParams(filters);

  urlPath += `?${params.toString()}`;
  return axios.get(urlPath, { responseType: "blob" }).then((response) => {
    const contentDisposition = response.headers["content-disposition"];
    const fileName = contentDisposition;
    return Promise.resolve({ blob: response.data, fileName: fileName });
  });
}
