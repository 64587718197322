import { facturaService } from "@/_services";

export const factura = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    download: {
      data: {},
      status: {},
    },
    downloadPreFactura: {
      data: {},
      status: {},
    },
    factura: {
      data: {},
      status: {},
    },
    edit: {
      data: {},
      status: {},
    },
    facturasExcel: {
      data: null,
      status: {},
    },
    facturar: {
      data: {},
      status: {},
    },
    rectificar: {
      data: null,
      status: {},
    },
    cancelar: {
      data: {},
      status: {},
    },
    descargaContable: {
      data: {},
      status: {},
    },
    sendFacturas: {
      data: [],
      status: {},
    },
  },
  mutations: {
    getfacturaRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getfacturaSuccess(state, facturas) {
      state.list.data = facturas;
      state.list.status = {
        loaded: true,
      };
    },
    getfacturaError(state, error) {
      state.list.data = [];
      state.list.status = {
        error,
      };
    },
    downloadFacturaRequest(state) {
      state.download.status = {
        loading: true,
      };
    },
    downloadFacturaError(state, error) {
      state.download.data = null;
      state.download.status = {
        error,
      };
    },
    downloadFacturaSuccess(state, factura) {
      state.download.data = factura;
      state.download.status = {
        loaded: true,
      };
    },
    downloadPreFacturaRequest(state) {
      state.downloadPreFactura.status = {
        downloading: true,
      };
    },
    downloadPreFacturaError(state, error) {
      state.downloadPreFactura.data = null;
      state.downloadPreFactura.status = {
        error,
      };
    },
    downloadPreFacturaSuccess(state, factura) {
      state.downloadPreFactura.data = factura;
      state.downloadPreFactura.status = {
        downloaded: true,
      };
    },
    getFacturaByIdRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getFacturaByIdSuccess(state, facturas) {
      state.factura.data = facturas;
      state.factura.status = {
        loaded: true,
      };
    },
    getFacturaByIdError(state, error) {
      state.factura.data = null;
      state.factura.status = {
        error,
      };
    },
    editFacturaRequest(state) {
      state.edit.status = {
        loading: true,
      };
    },
    editFacturaError(state, error) {
      state.edit.data = null;
      state.edit.status = {
        error,
      };
    },
    editFacturaSuccess(state, factura) {
      state.edit.data = factura;
      state.edit.status = {
        loaded: true,
      };
    },
    facturasExcelRequest(state) {
      state.facturasExcel.status = {
        loading: true,
      };
    },
    facturasExcelSuccess(state, pdf) {
      state.facturasExcel.data = pdf;
      state.facturasExcel.status = {
        loaded: true,
      };
    },
    facturasExcelError(state, error) {
      state.facturasExcel.data = null;
      state.facturasExcel.status = {
        error,
      };
    },
    rectificarFacturaRequest(state) {
      state.rectificar.status = {
        loading: true,
      };
    },
    rectificarFacturaError(state, error) {
      state.rectificar.data = null;
      state.rectificar.status = {
        error,
      };
    },
    rectificarFacturaSuccess(state, factura) {
      state.rectificar.data = factura;
      state.rectificar.status = {
        loaded: true,
      };
    },
    facturarRequest(state) {
      state.facturar.status = {
        loading: true,
      };
    },
    facturarSuccess(state, response) {
      state.facturar.data = response;
      state.facturar.status = {
        loaded: true,
      };
    },
    facturarError(state, error) {
      state.facturar.data = null;
      state.facturar.status = {
        error,
      };
    },
    cancelarRequest(state) {
      state.cancelar.status = {
        loading: true,
      };
    },
    cancelarSuccess(state, response) {
      state.cancelar.data = response;
      state.cancelar.status = {
        loaded: true,
      };
    },
    cancelarError(state, error) {
      state.cancelar.data = null;
      state.cancelar.status = {
        error,
      };
    },
    downloadContableRequest(state) {
      state.descargaContable.status = {
        loading: true,
      };
    },
    downloadContableSuccess(state, response) {
      state.descargaContable.data = response;
      state.descargaContable.status = {
        loaded: true,
      };
    },
    downloadContableError(state, error) {
      state.descargaContable.data = null;
      state.descargaContable.status = {
        error,
      };
    },
    sendFacturasRequest(state) {
      state.sendFacturas.status = {
        sending: true,
      };
    },
    sendFacturasError(state, error) {
      state.sendFacturas.data = [];
      state.sendFacturas.status = {
        sending: false,
        error,
      };
    },
    sendFacturasSuccess(state, res) {
      state.sendFacturas.data = res;
      state.sendFacturas.status = {
        sended: true,
      };
    },
  },
  actions: {
    getFacturas({ commit }, req) {
      commit("getfacturaRequest");
      facturaService
        .getFacturas(req.pagination, req.filters)
        .then((facturas) => {
          commit("getfacturaSuccess", facturas);
        })
        .catch((error) => {
          commit("getfacturaError", error);
        });
    },
    downloadFactura({ commit }, req) {
      commit("downloadFacturaRequest");
      facturaService
        .downloadFactura(req)
        .then((factura) => {
          commit("downloadFacturaSuccess", factura);
        })
        .catch((error) => {
          commit("downloadFacturaError", error);
        });
    },
    downloadPreFactura({ commit }, req) {
      commit("downloadPreFacturaRequest");
      facturaService
        .downloadPreFactura(req)
        .then((factura) => {
          commit("downloadPreFacturaSuccess", factura);
        })
        .catch((error) => {
          commit("downloadPreFacturaError", error);
        });
    },
    facturasExcel({ commit }, request) {
      commit("facturasExcelRequest");
      facturaService
        .getFacturasAsExcel(request)
        .then((excel) => {
          commit("facturasExcelSuccess", excel);
        })
        .catch((error) => {
          commit("facturasExcelError", error);
        });
    },
    getFacturaById({ commit }, req) {
      commit("getFacturaByIdRequest");
      facturaService
        .getFacturaById(req)
        .then((factura) => {
          commit("getFacturaByIdSuccess", factura);
        })
        .catch((error) => {
          commit("getFacturaByIdError", error);
        });
    },
    editFactura({ commit }, body) {
      commit("editFacturaRequest");
      facturaService
        .editFactura(body)
        .then((response) => {
          commit("editFacturaSuccess", response);
        })
        .catch((error) => {
          commit("editFacturaError", error);
        });
    },
    facturarAccion({ commit }, req) {
      commit("facturarRequest");
      facturaService
        .accionFacturar(req)
        .then((response) => {
          commit("facturarSuccess", response);
        })
        .catch((error) => {
          commit("facturarError", error);
        });
    },
    rectificarFactura({ commit }, request) {
      commit("rectificarFacturaRequest");
      facturaService
        .rectificarFactura(request)
        .then((factura) => {
          commit("rectificarFacturaSuccess", factura);
        })
        .catch((error) => {
          commit("rectificarFacturaError", error);
        });
    },
    cancelarAccion({ commit }, req) {
      commit("cancelarRequest");
      facturaService
        .accionCancelar(req)
        .then((response) => {
          commit("cancelarSuccess", response);
        })
        .catch((error) => {
          commit("cancelarError", error);
        });
    },
    downloadContable({ commit }, req) {
      commit("downloadContableRequest");
      facturaService
        .downloadContable(req)
        .then((response) => {
          commit("downloadContableSuccess", response);
        })
        .catch((error) => {
          commit("downloadContableError", error);
        });
    },
    sendFacturas({ commit }, req) {
      commit("sendFacturasRequest");
      facturaService
        .sendFacturas(req)
        .then((response) => {
          commit("sendFacturasSuccess", response);
        })
        .catch((error) => {
          commit("sendFacturasError", error);
        });
    },
  },
};
