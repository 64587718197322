import { areasService } from "@/_services";
export const area = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    entity: {
      data: [],
      status: {},
    },
    delete: {
      data: [],
      status: {},
    },
    update: {
      data: [],
      status: {},
    },
    post: {
      data: [],
      status: {},
    },
  },
  mutations: {
    getAreasRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getAreasSuccess(state, response) {
      state.list.data = response;
      state.list.status = {
        loaded: true,
      };
    },
    getAreasError(state, error) {
      state.list.data = null;
      state.list.status = {
        error,
      };
    },
    deleteAreaRequest(state) {
      state.delete.status = {
        deleting: true,
      };
    },
    deleteAreaError(state, error) {
      state.delete.data = null;
      state.delete.status = {
        error,
      };
    },
    deleteAreaSuccess(state, response) {
      state.delete.data = response;
      state.delete.status = {
        deleted: true,
      };
    },
    postAreaRequest(state) {
      state.post.status = {
        creating: true,
      };
    },
    postAreaError(state, error) {
      state.post.data = null;
      state.post.status = {
        error,
      };
    },
    postAreaSuccess(state, response) {
      state.post.data = response;
      state.post.status = {
        created: true,
      };
    },
    updateAreaRequest(state) {
      state.update.status = {
        updating: true,
      };
    },
    updateAreaError(state, error) {
      state.update.data = null;
      state.update.status = {
        error,
      };
    },
    updateAreaSuccess(state, response) {
      state.update.data = response;
      state.update.status = {
        updated: true,
      };
    },
    getAreaByIdRequest(state) {
      state.entity.status = {
        loading: true,
      };
    },
    getAreaByIdError(state, error) {
      state.entity.data = null;
      state.entity.status = {
        error,
      };
    },
    getAreaByIdSuccess(state, response) {
      state.entity.data = response;
      state.entity.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getAreas({ commit }, request) {
      commit("getAreasRequest");
      areasService
        .getAreas(request.pagination, request.query)
        .then((response) => {
          commit("getAreasSuccess", response);
        })
        .catch((error) => {
          commit("getAreasError", error);
        });
    },
    deleteArea({ commit }, id) {
      commit("deleteAreaRequest");
      areasService
        .deleteArea(id)
        .then((res) => {
          commit("deleteAreaSuccess", res);
        })
        .catch((error) => {
          commit("deleteAreaError", error);
        });
    },
    postArea({ commit }, request) {
      commit("postAreaRequest");
      areasService
        .postArea(request)
        .then((res) => {
          commit("postAreaSuccess", res);
        })
        .catch((error) => {
          commit("postAreaError", error);
        });
    },
    updateArea({ commit }, request) {
      commit("updateAreaRequest");
      areasService
        .updateArea(request.id, request.body)
        .then((res) => {
          commit("updateAreaSuccess", res);
        })
        .catch((error) => {
          commit("updateAreaError", error);
        });
    },
    getAreaById({ commit }, id) {
      commit("getAreaByIdRequest");
      areasService
        .getAreaById(id)
        .then((res) => {
          commit("getAreaByIdSuccess", res);
        })
        .catch((error) => {
          commit("getAreaByIdError", error);
        });
    },
  },
};
