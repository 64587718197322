import {
  createAxiosLogistica,
  createAxiosHeadersLogistica,
} from "./axiosHttpMarconi";
import { franquiciaService } from "./franquicia.service";

export const stockService = {
  getStock,
  moveStock,
  ajustarStock,
  getExcel,
};

function getStock(pagination, filters) {
  this.usuario = JSON.parse(localStorage.getItem("user"));
  if (this.usuario.agrupacion)
    filters.franquicia = franquiciaService.getLocalFranquicia()._id;
  const axios = createAxiosLogistica();
  let urlPath = `logistica/stock`;
  const params = new URLSearchParams({ ...pagination, ...filters });

  urlPath += `?${params.toString()}`;
  return axios.get(urlPath);
}

function moveStock(body) {
  const franquicia = franquiciaService.getLocalFranquicia();
  const axios = createAxiosLogistica();
  let urlPath = `logistica/stock?franquicia=${franquicia._id}`;
  return axios.put(urlPath, body);
}

function ajustarStock(body) {
  const franquicia = franquiciaService.getLocalFranquicia();

  const axios = createAxiosLogistica();
  let urlPath = `logistica/stock?franquicia=${franquicia._id}`;
  return axios.post(urlPath, body);
}

function getExcel(filters) {
  this.usuario = JSON.parse(localStorage.getItem("user"));
  if (this.usuario.agrupacion)
    filters.franquicia = franquiciaService.getLocalFranquicia()._id;

  const axios = createAxiosHeadersLogistica();
  let urlPath = `logistica/stock/excel`;
  const params = new URLSearchParams(filters);

  urlPath += `?${params.toString()}`;
  return axios.get(urlPath, { responseType: "blob" }).then((response) => {
    const contentDisposition = response.headers["content-disposition"];
    const fileName = contentDisposition;
    return Promise.resolve({ blob: response.data, fileName: fileName });
  });
}
