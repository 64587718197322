import { batchesService } from "@/_services";
export const batch = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    blob: {
      data: [],
      status: {},
    },
  },
  mutations: {
    getBatchesRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getBatchesSuccess(state, response) {
      state.list.data = response;
      state.list.status = {
        loaded: true,
      };
    },
    getBatchesError(state, error) {
      state.list.data = null;
      state.list.status = {
        error,
      };
    },
    getBatchByIdRequest(state) {
      state.blob.status = {
        downloading: true,
      };
    },
    getBatchByIdError(state, error) {
      state.blob.data = null;
      state.blob.status = {
        error,
      };
    },
    getBatchByIdSuccess(state, response) {
      state.blob.data = response;
      state.blob.status = {
        downloaded: true,
      };
    },
  },
  actions: {
    getBatches({ commit }, request) {
      commit("getBatchesRequest");
      batchesService
        .getBatches(request.pagination, request.query)
        .then((response) => {
          commit("getBatchesSuccess", response);
        })
        .catch((error) => {
          commit("getBatchesError", error);
        });
    },
    download({ commit }, id) {
      commit("getBatchByIdRequest");
      batchesService
        .download(id)
        .then((res) => {
          commit("getBatchByIdSuccess", res);
        })
        .catch((error) => {
          commit("getBatchByIdError", error);
        });
    },
  },
};
