import { createAxiosLogistica } from "./axiosHttpMarconi";

export const areasService = {
  getAreas,
  deleteArea,
  postArea,
  updateArea,
  getAreaById,
};

function getAreas(pagination, filters) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/area`;
  const params = new URLSearchParams({ ...pagination, ...filters });

  urlPath += `?${params.toString()}`;
  return axios.get(urlPath);
}

function deleteArea(areaId) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/area/${areaId}`;
  return axios.delete(urlPath);
}

function postArea(request) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/area`;
  return axios.post(urlPath, request);
}

function updateArea(id, body) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/area/${id}`;
  return axios.put(urlPath, body);
}

function getAreaById(id) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/area/${id}`;
  return axios.get(urlPath);
}
