import { ordenesEntradaService } from "@/_services";
export const ordenEntrada = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    entity: {
      data: [],
      status: {},
    },
    cancel: {
      data: [],
      status: {},
    },
    update: {
      data: [],
      status: {},
    },
    post: {
      data: [],
      status: {},
    },
    ubicar: {
      data: [],
      status: {},
    },
    copiar: {
      data: [],
      status: {},
    },
    download: {
      data: [],
      status: {},
    },
  },
  mutations: {
    getOrdenesEntradaRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getOrdenesEntradaSuccess(state, response) {
      state.list.data = response;
      state.list.status = {
        loaded: true,
      };
    },
    getOrdenesEntradaError(state, error) {
      state.list.data = null;
      state.list.status = {
        error,
      };
    },
    cancelOrdenEntradaRequest(state) {
      state.cancel.status = {
        canceling: true,
      };
    },
    cancelOrdenEntradaError(state, error) {
      state.cancel.data = null;
      state.cancel.status = {
        error,
      };
    },
    cancelOrdenEntradaSuccess(state, response) {
      state.cancel.data = response;
      state.cancel.status = {
        canceled: true,
      };
    },
    postOrdenEntradaRequest(state) {
      state.post.status = {
        creating: true,
      };
    },
    postOrdenEntradaError(state, error) {
      state.post.data = null;
      state.post.status = {
        error,
      };
    },
    postOrdenEntradaSuccess(state, response) {
      state.post.data = response;
      state.post.status = {
        created: true,
      };
    },
    updateOrdenEntradaRequest(state) {
      state.update.status = {
        updating: true,
      };
    },
    updateOrdenEntradaError(state, error) {
      state.update.data = null;
      state.update.status = {
        error,
      };
    },
    updateOrdenEntradaSuccess(state, response) {
      state.update.data = response;
      state.update.status = {
        updated: true,
      };
    },
    getOrdenEntradaByIdRequest(state) {
      state.entity.status = {
        loading: true,
      };
    },
    getOrdenEntradaByIdError(state, error) {
      state.entity.data = null;
      state.entity.status = {
        error,
      };
    },
    getOrdenEntradaByIdSuccess(state, response) {
      state.entity.data = response;
      state.entity.status = {
        loaded: true,
      };
    },
    ubicarItemRequest(state) {
      state.ubicar.status = {
        loading: true,
      };
    },
    ubicarItemError(state, error) {
      state.ubicar.data = null;
      state.ubicar.status = {
        error,
      };
    },
    ubicarItemSuccess(state, response) {
      state.ubicar.data = response;
      state.ubicar.status = {
        loaded: true,
      };
    },
    copiarOrdenEntradaRequest(state) {
      state.copiar.status = {
        loading: true,
      };
    },
    copiarOrdenEntradaError(state, error) {
      state.copiar.data = null;
      state.copiar.status = {
        error,
      };
    },
    copiarOrdenEntradaSuccess(state, response) {
      state.copiar.data = response;
      state.copiar.status = {
        loaded: true,
      };
    },
    downloadOrdenEntradaRequest(state) {
      state.download.status = {
        downloading: true,
      };
    },
    downloadOrdenEntradaError(state, error) {
      state.download.data = null;
      state.download.status = {
        error,
      };
    },
    downloadOrdenEntradaSuccess(state, response) {
      state.download.data = response;
      state.download.status = {
        downloaded: true,
      };
    },
  },
  actions: {
    getOrdenesEntrada({ commit }, request) {
      commit("getOrdenesEntradaRequest");
      ordenesEntradaService
        .getOrdenesEntrada(request.pagination, request.query)
        .then((response) => {
          commit("getOrdenesEntradaSuccess", response);
        })
        .catch((error) => {
          commit("getOrdenesEntradaError", error);
        });
    },
    cancelOrdenEntradaById({ commit }, id) {
      commit("cancelOrdenEntradaRequest");
      ordenesEntradaService
        .cancelOrdenEntradaById(id)
        .then((res) => {
          commit("cancelOrdenEntradaSuccess", res);
        })
        .catch((error) => {
          commit("cancelOrdenEntradaError", error);
        });
    },
    postOrdenEntrada({ commit }, request) {
      commit("postOrdenEntradaRequest");
      ordenesEntradaService
        .postOrdenEntrada(request)
        .then((res) => {
          commit("postOrdenEntradaSuccess", res);
        })
        .catch((error) => {
          commit("postOrdenEntradaError", error);
        });
    },
    updateOrdenEntrada({ commit }, request) {
      commit("updateOrdenEntradaRequest");
      ordenesEntradaService
        .updateOrdenEntrada(request.id, request.body)
        .then((res) => {
          commit("updateOrdenEntradaSuccess", res);
        })
        .catch((error) => {
          commit("updateOrdenEntradaError", error);
        });
    },
    getOrdenEntradaById({ commit }, id) {
      commit("getOrdenEntradaByIdRequest");
      ordenesEntradaService
        .getOrdenEntradaById(id)
        .then((res) => {
          commit("getOrdenEntradaByIdSuccess", res);
        })
        .catch((error) => {
          commit("getOrdenEntradaByIdError", error);
        });
    },
    ubicarItem({ commit }, req) {
      commit("ubicarItemRequest");
      ordenesEntradaService
        .ubicarItem(req.id, req.body, req.query)
        .then((res) => {
          commit("ubicarItemSuccess", res);
        })
        .catch((error) => {
          commit("ubicarItemError", error);
        });
    },
    copiarOrdenEntradaById({ commit }, id) {
      commit("copiarOrdenEntradaRequest");
      ordenesEntradaService
        .copiarOrdenEntradaById(id)
        .then((res) => {
          commit("copiarOrdenEntradaSuccess", res);
        })
        .catch((error) => {
          commit("copiarOrdenEntradaError", error);
        });
    },
    downloadById({ commit }, id) {
      commit("downloadOrdenEntradaRequest");
      ordenesEntradaService
        .downloadById(id)
        .then((res) => {
          commit("downloadOrdenEntradaSuccess", res);
        })
        .catch((error) => {
          commit("downloadOrdenEntradaError", error);
        });
    },
  },
};
