import createAxios from "./axiosHttpMarconi";
export const ticketBaiService = {
  getConfig,
  registerConfig,
};

function getConfig() {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/fiskaly/ticket-bai`;
  return axios.get(urlPath);
}

function registerConfig(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/fiskaly/ticket-bai`;
  return axios.post(urlPath, body);
}
