import { createAxiosLogistica } from "./axiosHttpMarconi";
import { franquiciaService } from "./franquicia.service";

export const packsService = {
  getPacks,
  deletePack,
  postPack,
  updatePack,
  getPackById,
};

function getPacks(pagination, filters) {
  this.usuario = JSON.parse(localStorage.getItem("user"));
  if (this.usuario.agrupacion)
    filters.franquicia = franquiciaService.getLocalFranquicia()._id;
  const axios = createAxiosLogistica();
  let urlPath = `logistica/pack`;
  const params = new URLSearchParams({ ...pagination, ...filters });

  urlPath += `?${params.toString()}`;
  return axios.get(urlPath);
}

function deletePack(packId) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/pack/${packId}`;
  return axios.delete(urlPath);
}

function postPack(request) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/pack`;
  return axios.post(urlPath, request);
}

function updatePack(id, body) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/pack/${id}`;
  return axios.put(urlPath, body);
}

function getPackById(id) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/pack/${id}`;
  return axios.get(urlPath);
}
